export default {
  name: 'v-layout-footer',
  data() {
    return {
      links: false,
      fab: false,
      url: null,
      title: null,
    }
  },
  methods: {

    /**
     * 
     */
    getTop(event) {
      return window.pageYOffset || event.target.scrollTop || 0
    },

    /**
     * 
     */
    handleWindowScroll(event) {
      this.fab = this.getTop(event) > 150
    },

    /**
     * 
     */
    setVideo(url = null, title = null) {
      this.url = `https://drive.google.com/file/d/${ url }/preview`
      this.title = title
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleWindowScroll)
  }
}